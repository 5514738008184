<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/about.jpg')"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <h1 class="title">About Planet Doodell.</h1>
        </div>
      </div>
    </div>
    <div class="section section-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">For Science!</h2>
            <p class="description">
              Welcome to Doodell, a tiny planet on the edge of a galaxy, the name of which we can’t pronounce. Science discovered the planet accidentally when their space hopper tripped over a flying Nad. The Nad survived... thanks for asking.
              <br /><br />
              For eight rotations, Science has documented the different species discovered on Doodell, from the frozen tundra of Burr, to the desert sands of Dune, and deep under the Specific Ocean.
              <br /><br />
              This is a wacky world filled with weird and wonderful creatures.
              <br /><br />
              <router-link to="books" class="mx-2"><n-button type="primary" round>Grab a book</n-button></router-link>
              <router-link to="creatures" class="mx-2"><n-button type="primary" round>Meet the creatures</n-button></router-link>

              <a title="Buy the books on Amazon" class="mx-1"
                href="https://www.amazon.ca/s?k=Planet+Doodell&i=stripbooks&ref=nb_sb_noss"
                target="_blank">
                <n-button type="primary" round><i class="fab fa-amazon"></i></n-button>
              </a>
              <a title="Buy NFTs on OpenSea"  class="mx-1"
                href="https://opensea.io/collection/planet-doodell"
                target="_blank">
                <n-button type="primary" round> <i class="fab fa-ethereum"></i></n-button>
              </a>
              <a title="Follow us on Instagram" class="mx-1"
                href="https://www.instagram.com/planetdoodell"
                target="_blank">
                <n-button type="primary" round><i class="fab fa-instagram"></i></n-button>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="section text-center pt-0">
      <div class="container">
        <h3 class="title">About the world.</h3>
        <p>There are 7 different biomes in Doodell:</p>
        <div class="row  justify-content-md-center">
          <div class="col-md-4 col-sm-6">
            <h4>Sprout Jungle</h4>
            <img src="/img/biomes/sprout.png" />
          </div>
          <div class="col-md-4 col-sm-6">
            <h4>Specific Ocean</h4>
            <img src="/img/biomes/ocean.png" />
          </div>
          <div class="col-md-4 col-sm-6">
            <h4>Tundra of Burr</h4>
            <img src="/img/biomes/burr.png" />
          </div>
          <div class="col-md-4 col-sm-6">
            <h4>Sands of Dune</h4>
            <img src="/img/biomes/dune.png" />
          </div>
          <div class="col-md-4 col-sm-6">
            <h4>Grassby</h4>
            <img src="/img/biomes/grassby.png" />
          </div>
          <div class="col-md-4 col-sm-6">
            <h4>Wastelands of Ferno</h4>
            <img src="/img/biomes/ferno.png" />
          </div>
          <div class="col-md-4 col-sm-6">
            <h4>The Above</h4>
            <img src="/img/biomes/above.png" />
          </div>
        </div>
      </div>
    </div>

    <div class="section text-center pt-0">
      <h3 class="title">About the solar system.</h3>
      <img src="/img/solarsystem.png" title="The Doodell Solarsystem" style="max-width:100%;" />
    </div>

    <div class="section section-team text-center  pt-0">
      <div class="container">
        <h3 class="title">About the author.</h3>
        <div class="team">
          <div class="row justify-content-md-center">
            <div class="col-md-6">
              <div class="team-player">
                <img
                  src="img/avatar.png"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title pt-2">John Gill</h4>
                <p class="category text-primary">Creator of worlds</p>
                <p class="description">
                  I've been drawing cartoons and doodles since I was a kid, but only recently did I decide to do anything with them. I'm a big fan of weird creatures and imaginative worlds, such as Jim Henson's Dark Crystal and Labyrinth.<br/><br/>
                  By trade, I'm a software engineer so I naturally like to create in the digital realm. Home is in Vancouver, BC, Canada, in the mountains as much as possible. <br/><br/>
                  My artistic process is... to have no real process. I put the pencil on the paper and let it move around until something interesting takes shape. Very little forethought goes into any of my Doodells, they are purely a product of a childish and overactive imagination. 
                </p>
                <a href="https://opensea.io/accounts/Rusty404" target="_blank" class="btn btn-primary btn-icon btn-round" title="Find me on OpenSea"
                  ><i class="fab fa-ethereum"></i
                ></a>
                <a href="https://www.instagram.com/coastmountaineer/" target="_blank" class="btn btn-primary btn-icon btn-round" title="Find me on Instagram"
                  ><i class="fab fa-instagram"></i
                ></a>
                <a href="https://www.linkedin.com/in/john-gill-428a7864/" target="_blank" class="btn btn-primary btn-icon btn-round" title="Find me on LinkedIn"
                  ><i class="fab fa-linkedin"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button } from '@/components';
export default {
  components: {
    [Button.name]: Button
  },
  name: 'about',
  bodyClass: 'landing-page'
};
</script>
<style></style>
