<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        :style="'background-image: url(\'/img/biomes/' + creature.biome + '.png\')'">
      </parallax>
    </div>
    <div class="section pb-5">
      <div class="container">
        <div class="profile-wrapper text-center">
          <div class="profile-pic">
            <img :src="imgUrl" :title="creature.name" :alt="creature.name" />
          </div>
          <h1 class="mt-1 pt-2 title">{{creature.name}}</h1>
          <span class="mx-2 volume btn btn-round btn-primary btn-lg">Vol. {{creature.volume}}</span>
          <span class="mx-2 biome btn btn-round btn-primary btn-simple btn-lg">{{biomeName}}</span>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="container">
        <div class="row  text-center">
          <div class="col-md-6">
            <p class="description">
              <strong>Bio:</strong><br /> {{creature.description}}
            </p>
            <p class="description">
              <strong>Fun Fact:</strong><br /> {{creature.funFact}}
            </p>
            <p class="my-5">
              <a :href="creature.openseaUrl" target="_blank" class="btn btn-round btn-primary btn-lg"><i class="fab fa-ethereum mr-2"></i> Grab the NFT</a>
              <br />(Only {{creature.quantity}} exist{{creature.quantity > 1 ? '' : 's'}})
              <br /><br />NFTs (non-fungible tokens) are available for purchase on <a href="https://opensea.io/collection/planet-doodell" target="_blank">OpenSea</a>.
            </p>
            <hr />
          </div>
          <div class="col-md-2"></div>
          <div class="col-md-4">
            <div class="progress-bars">
              <div class="progress-wrapper">
                <strong>Rarity</strong>
                <div class="row">
                  <div class="col-2">{{creature.rarity}}</div>
                  <div class="col-8">
                    <div class="bar"><div class="bar-inner bg-primary" :style="'width:' + rarityPerc + '%'"></div></div>
                  </div>
                  <div class="col-2">6</div>
                </div>
              </div>
              <div class="progress-wrapper">
                <strong>Oddity</strong>
                <div class="row">
                  <div class="col-2">{{creature.oddity}}</div>
                  <div class="col-8">
                    <div class="bar"><div class="bar-inner bg-primary" :style="'width:' + oddityPerc + '%'"></div></div>
                  </div>
                  <div class="col-2">10</div>
                </div>
              </div>
              <div class="progress-wrapper">
                <strong>Jollity</strong>
                <div class="row">
                  <div class="col-2">{{creature.jollity}}</div>
                  <div class="col-8">
                    <div class="bar"><div class="bar-inner bg-primary" :style="'width:' + jollityPerc + '%'"></div></div>
                  </div>
                  <div class="col-2">10</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { creatures } from '../resources/creatures'
export default {
  name: 'creature',
  bodyClass: 'profile-page',
  components: {
  },
  data() {
    return {
      creature: null
    }
  },
  created() {
    if (this.$route.params.id) {
      this.creature = creatures.find(x => x.url === this.$route.params.id);
      if (!this.creature) {
        this.$router.push('/creatures');
      }
    } else {
      this.$router.push('/creatures');
    }
  },
  computed: {
    biomeName() {
      if (this.creature) {
        switch (this.creature.biome) {
          case 'burr':
            return 'Tundra of Burr';
          case 'above':
            return 'The Above';
          case 'grassby':
            return 'Grassby';
          case 'ferno':
            return 'Wastelands of Ferno';
          case 'sprout':
            return 'Sprout Jungle';
          case 'dune':
            return 'Sands of Dune';
          case 'ocean':
            return 'Specific Ocean';
          default:
            return '';
        }
      }
      return '';
    },
    imgUrl: function() {
      if (this.creature) {
        return '/img/creatures/' + this.creature.imgsrc.toLowerCase();
      }
      return '';
    },
    rarityPerc: function() {
      if (this.creature) {
        return (this.creature.rarity / 6) * 100;
      }
      return 0;
    },
    oddityPerc: function() {
      if (this.creature) {
        return (this.creature.oddity / 10) * 100;
      }
      return 0;
    },
    jollityPerc: function() {
      if (this.creature) {
        return (this.creature.jollity / 10) * 100;
      }
      return 0;
    }
  }
};
</script>
<style>
  .profile-wrapper {
    max-width:600px;
    margin:-440px auto 0px auto;
  }
  .progress-bars {
    border:1px solid #845f9c;
    border-radius:10px;
    padding:40px;
  }
  .progress-wrapper {
    margin-bottom:30px;
  }
  .progress-wrapper:last-child {
    margin-bottom:0px;
  }
  .progress-wrapper .bar {
    height: 20px;
    border-radius: 10px;
    overflow:hidden;
    border:1px solid #bbbbbb;
  }
  .progress-wrapper .bar-inner {
    height: 20px;
  }
</style>
