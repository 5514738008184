<template>
  <div>
    <div class="page-header clear-filter">
      <parallax
        class="page-header-image"
        style="background-image:url('img/header.jpg')"
      >
      </parallax>
      <div class="container">
        
      </div>
    </div>
    
    <div class="section">
      <div class="container text-center">
        <div class="row justify-content-md-center">
          <div class="col-md-12 col-lg-8">
            <h2 class="title mb-5">Welcome, Earthling.</h2>
            <div class="row">
              <div class="col-md-3 pb-5">
                <img src="/img/creatures/spacehopper.png" alt="Spacehopper" title="Spacehopper" style="max-width:160px;" />
              </div>
              <div class="col-md-1"></div>
              <div class="col-md-8">
                <h5 class="description">
                  You step out of your Space Hopper and take in a deep breath.<br /><br />
                  <i>"Is this breathable? Is the air poisonous? Will my voice go high-pitched? What's that smell?"</i><br /><br />
                  Your thoughts are interrupted as you realize the beauty of the landscape surrounding you. Rolling green hills, lush jungle, towering snow-capped peaks, shimmering oceans. And as your eyes adjust to the brightness of this world, you realize that it is teeming with life... 
                  <strong>Weird, wacky, wonderful life.</strong>
                </h5>
              </div>
            </div>

            <hr />

            <div class="row mt-5">
              <div class="col-6">
                <router-link to="creatures"><img src="/img/allchars.png" /></router-link>
              </div>
              <div class="col-6  my-auto">
                <h3><router-link to="creatures">Meet the creatures</router-link></h3>
                We don't bite, honestly!
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-6">
                <router-link to="about"><img src="/img/biomes/grassby.png" /></router-link>
              </div>
              <div class="col-6  my-auto">
                <h3><router-link to="about">Explore the world</router-link></h3>
                You'll get used to the smell.
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-6">
                <router-link to="books"><img src="/img/volume1.png" /></router-link>
              </div>
              <div class="col-6  my-auto">
                <h3><router-link to="books">Grab a book</router-link></h3>
                For your endless fondling.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Parallax } from '@/components';

export default {
  name: 'index',
  bodyClass: 'index-page',
  components: {
    Parallax
  }
};
</script>
<style></style>
