<template>
  <navbar
    position="fixed"
    type="primary"
    :transparent="transparent"
    :color-on-scroll="colorOnScroll"
    menu-classes="ml-auto"
  >
    <template>
      <router-link v-popover:popover1 class="navbar-brand" to="/">
        <img src="img/logo-singleline.png" style="max-width:300px; width:100%;" />
      </router-link>
    </template>
    <template slot="navbar-menu">
      <li class="nav-item">
        <router-link to="/about" class="nav-link">
          <p>About</p>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link to="/creatures" class="nav-link">
          <p>Creatures</p>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link to="/books" class="nav-link">
          <p>Books</p>
        </router-link>
      </li>
      
      <li class="nav-item">
        <a
          class="nav-link"
          rel="tooltip"
          title="Buy the books on Amazon"
          data-placement="bottom"
          href="https://www.amazon.ca/s?k=Planet+Doodell&i=stripbooks&ref=nb_sb_noss"
          target="_blank"
        >
          <i class="fab fa-amazon"></i>
          <p class="d-lg-none d-xl-none">Buy the books on Amazon</p>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          rel="tooltip"
          title="Buy NFTs on OpenSea"
          data-placement="bottom"
          href="https://opensea.io/collection/planet-doodell"
          target="_blank"
        >
          <i class="fab fa-ethereum"></i>
          <p class="d-lg-none d-xl-none">Buy NFTs on OpenSea</p>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          rel="tooltip"
          title="Follow us on Instagram"
          data-placement="bottom"
          href="https://www.instagram.com/planetdoodell"
          target="_blank"
        >
          <i class="fab fa-instagram"></i>
          <p class="d-lg-none d-xl-none">Instagram</p>
        </a>
      </li>
    </template>
  </navbar>
</template>

<script>
import { Navbar } from '@/components';
import { Popover } from 'element-ui';
export default {
  name: 'main-navbar',
  props: {
    transparent: Boolean,
    colorOnScroll: Number
  },
  components: {
    Navbar,
    [Popover.name]: Popover
  }
};
</script>

<style scoped></style>
