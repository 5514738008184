<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/characters.png')"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <h1 class="title">Creatures.</h1>
        </div>
      </div>
    </div>
    <div class="section section-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">Get to know us.</h2>
            <p>Volume 1 creatures below. Volume 2 creatures coming soon!</p>
          </div>
        </div>
        <div class="separator separator-primary"></div>
      </div>
    </div>
    <div class="section section-team text-center pt-0">
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-lg-3 col-md-4" v-for="c in creatures" v-bind:key="c.name">
            <creature-card :creature="c"></creature-card>
          </div>
        </div>
      </div>
    </div>
    <!--
    <div class="container">
      <div  v-for="c in creatures" v-bind:key="c.name">
        <strong>{{c.name}}</strong><br><br>
        https://planetdoodell.com/#/creatures/{{c.url}}
        <br><br>-------<br>
        {{c.description}}<br><br>

        *FUN FACT:* {{c.funFact}}<br><br>

        **Only {{c.quantity}} of this creature have been minted!**<br><br>

        The price of Doodell creatures is defined by their Rarity value and the quantity that exist.
        <br><br>-------
        
        <br><br>
        Biome: {{biome(c.biome)}}
        <br><br>
        Volume: 1
        <br><br>
        Jollity: {{c.jollity}} / 10
        <br>
        Oddity: {{c.oddity}} / 10
        <br>
        Rarity: {{c.rarity}} / 6
        <br>
        Download a free PDF copy of the [Encyclopedia of Planet Doodell Volume 1](https://planetdoodell.com/file/pdf/9aac0ad7-031c-4ba0-bbe3-ad08f1c8fc5f_1.pdf)!
        <hr/>
      </div>
    </div>
    -->
  </div>
</template>
<script>
import { Button, FormGroupInput } from '@/components';
import CreatureCard from './components/CreatureCard';
import { creatures } from '../resources/creatures'
export default {
  name: 'creature-list',
  bodyClass: 'landing-page',
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    CreatureCard
  },
  data() {
    return {
      creatures: creatures
    };
  },
  methods: {
    biome: function(b) {
      switch (b) {
        case 'burr':
          return 'Tundra of Burr';
        case 'above':
          return 'The Above';
        case 'grassby':
          return 'Grassby';
        case 'ferno':
          return 'Wastelands of Ferno';
        case 'sprout':
          return 'Sprout Jungle';
        case 'dune':
          return 'Sands of Dune';
        case 'ocean':
          return 'Specific Ocean';
        default:
          return '';
      }
    }
  }
};
</script>
<style></style>
