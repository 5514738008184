import Vue from 'vue';
import App from './App.vue';
import router from './router';
import NowUiKit from './plugins/now-ui-kit';
import { initializeApp } from "firebase/app";


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDlnSEHoYIB7mrt03ZYbhFUxBrp_u7LsQw",
  authDomain: "planetdoodell.firebaseapp.com",
  projectId: "planetdoodell",
  storageBucket: "planetdoodell.appspot.com",
  messagingSenderId: "913749193269",
  appId: "1:913749193269:web:3f79d7d5011a5689bb377c"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

Vue.config.productionTip = false;

Vue.use(NowUiKit);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
