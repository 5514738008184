<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/books.png')"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <h1 class="title">Books.</h1>
        </div>
      </div>
    </div>
    <div class="section section-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">Endless Fondling.</h2>
            <h5 class="description">
              There are 2 volumes of the Encyclopedia of Planet Doodell, available in E-book and paperback from Amazon. Each book contains 26 unique and wacky characters, as well as back-story about Planet Doodell and what Science has discovered. 
            </h5>
          </div>
        </div>
        <div class="separator separator-primary"></div>
        <div class="section-story-overview">
          <div class="row">
            <div class="col-md-6">
              <div
                class="image-container image-left mb-5"
                style="background-image: url('img/F-1.png')"
              >
              </div>
            </div>
            <div class="col-md-5">
              <!-- First image on the right side, above the article -->
              <div
                class="image-container image-right d-none d-sm-block"
                style="background-image: url('img/F-2.png')"
              ></div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-5">
              <h3 class="mt-5">
                Volume 1
              </h3>
              <p><img src="/img/volume1.png" title="volume 1" style="width:100%;max-width:300px;" /></p>
              <p>
                The first volume of the Encyclopedia of Planet Doodell introduces Doodell, 26 unique characters and the first journey Science made to discover this new world.
              </p>
              <p>
                Available in e-book on Amazon in the following countries:<br />
                <b><a target="_blank" href="https://kdp.amazon.com/amazon-dp-action/us/dualbookshelf.marketplacelink/B08817QNDR">USA</a><span style="background-color: transparent;">,&nbsp;</span>
                <a target="_blank" href="https://kdp.amazon.com/amazon-dp-action/uk/dualbookshelf.marketplacelink/B08817QNDR">UK</a><span style="background-color: transparent;">,&nbsp;</span>
                <a target="_blank" href="https://kdp.amazon.com/amazon-dp-action/de/dualbookshelf.marketplacelink/B08817QNDR">DE</a><span style="background-color: transparent;">,&nbsp;</span>
                <a target="_blank" href="https://kdp.amazon.com/amazon-dp-action/fr/dualbookshelf.marketplacelink/B08817QNDR">FR</a><span style="background-color: transparent;">,&nbsp;</span>
                <a target="_blank" href="https://kdp.amazon.com/amazon-dp-action/es/dualbookshelf.marketplacelink/B08817QNDR">ES</a><span style="background-color: transparent;">,&nbsp;</span>
                <a target="_blank" href="https://kdp.amazon.com/amazon-dp-action/it/dualbookshelf.marketplacelink/B08817QNDR">IT</a><span style="background-color: transparent;">,&nbsp;</span>
                <a target="_blank" href="https://kdp.amazon.com/amazon-dp-action/nl/dualbookshelf.marketplacelink/B08817QNDR">NL</a><span style="background-color: transparent;">,&nbsp;</span>
                <a target="_blank" href="https://kdp.amazon.com/amazon-dp-action/jp/dualbookshelf.marketplacelink/B08817QNDR">JP</a><span style="background-color: transparent;">,&nbsp;</span>
                <a target="_blank" href="https://kdp.amazon.com/amazon-dp-action/br/dualbookshelf.marketplacelink/B08817QNDR">BR</a><span style="background-color: transparent;">,&nbsp;</span>
                <a target="_blank" href="https://kdp.amazon.com/amazon-dp-action/ca/dualbookshelf.marketplacelink/B08817QNDR">CA</a><span style="background-color: transparent;">,&nbsp;</span>
                <a target="_blank" href="https://kdp.amazon.com/amazon-dp-action/mx/dualbookshelf.marketplacelink/B08817QNDR">MX</a><span style="background-color: transparent;">,&nbsp;</span>
                <a target="_blank" href="https://kdp.amazon.com/amazon-dp-action/au/dualbookshelf.marketplacelink/B08817QNDR">AU</a><span style="background-color: transparent;">,&nbsp;</span>
                <a target="_blank" href="https://kdp.amazon.com/amazon-dp-action/in/dualbookshelf.marketplacelink/B08817QNDR">IN</a></b> 
              </p>
              <p>
                Available in paperback on Amazon in the following countries:<br />
                <b><a target="_blank" href="https://kdp.amazon.com/amazon-dp-action/us/dualbookshelf.marketplacelink/B08849VHRR">USA</a>,&nbsp;
                <a target="_blank" href="https://kdp.amazon.com/amazon-dp-action/uk/dualbookshelf.marketplacelink/B08849VHRR">UK</a>,&nbsp;
                <a target="_blank" href="https://kdp.amazon.com/amazon-dp-action/de/dualbookshelf.marketplacelink/B08849VHRR">DE</a>,&nbsp;
                <a target="_blank" href="https://kdp.amazon.com/amazon-dp-action/fr/dualbookshelf.marketplacelink/B08849VHRR">FR</a>,&nbsp;
                <a target="_blank" href="https://kdp.amazon.com/amazon-dp-action/es/dualbookshelf.marketplacelink/B08849VHRR">ES</a>,&nbsp;
                <a target="_blank" href="https://kdp.amazon.com/amazon-dp-action/it/dualbookshelf.marketplacelink/B08849VHRR">IT</a>,&nbsp;
                <a target="_blank" href="https://kdp.amazon.com/amazon-dp-action/jp/dualbookshelf.marketplacelink/B08849VHRR">JP</a>,&nbsp;
                <a target="_blank" href="https://kdp.amazon.com/amazon-dp-action/ca/dualbookshelf.marketplacelink/B08849VHRR">CA</a>.&nbsp;</b>
              </p>
              <p>
                <a title="Buy e-book on Amazon.com" class="mx-1"
                  href="https://www.amazon.com/dp/B08817QNDR"
                  target="_blank">
                  <n-button type="primary" round>Buy e-book on Amazon.com</n-button>
                </a>
                <a title="Buy paperback on Amazon.com" class="mx-1"
                  href="https://www.amazon.com/dp/B08849VHRR"
                  target="_blank">
                  <n-button type="primary" round>Buy paperback on Amazon.com</n-button>
                </a>
              </p>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-5">
              <h3 class="mt-5">
                Volume 2
              </h3>
              <p><img src="/img/volume2.png" title="volume 2" style="width:100%;max-width:300px;" /></p>
              <p>
                The second volume of the Encyclopedia of Planet Doodell explains more about the solar system and the biomes in Doodell, as well as introducing 26 new characters.
              </p>
              <p>
                Available in paperback on Amazon in the following countries:<br />
                <b><a target="_blank" href="https://www.amazon.com/Encyclopedia-Planet-Doodell-John-Gill/dp/B08P1KMXMW">USA</a>,&nbsp;
                <a target="_blank" href="https://www.amazon.co.uk/Encyclopedia-Planet-Doodell-John-Gill/dp/B08P1KMXMW">UK</a>,&nbsp;
                <a target="_blank" href="https://www.amazon.de/Encyclopedia-Planet-Doodell-John-Gill/dp/B08P1KMXMW">DE</a>,&nbsp;
                <a target="_blank" href="https://www.amazon.fr/Encyclopedia-Planet-Doodell-John-Gill/dp/B08P1KMXMW">FR</a>,&nbsp;
                <a target="_blank" href="https://www.amazon.es/Encyclopedia-Planet-Doodell-John-Gill/dp/B08P1KMXMW">ES</a>,&nbsp;
                <a target="_blank" href="https://www.amazon.it/Encyclopedia-Planet-Doodell-John-Gill/dp/B08P1KMXMW">IT</a>,&nbsp;
                <a target="_blank" href="https://www.amazon.co.jp/Encyclopedia-Planet-Doodell-John-Gill/dp/B08P1KMXMW">JP</a>,&nbsp;
                <a target="_blank" href="https://www.amazon.ca/Encyclopedia-Planet-Doodell-John-Gill/dp/B08P1KMXMW">CA</a>.&nbsp;</b>
              </p>
              <p>
                <a title="Buy paperback on Amazon.com" class="mx-1"
                  href="https://www.amazon.com/Encyclopedia-Planet-Doodell-John-Gill/dp/B08P1KMXMW"
                  target="_blank">
                  <n-button type="primary" round>Buy paperback on Amazon.com</n-button>
                </a>
              </p>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-6">
              <div
                class="image-container image-left mb-5"
                style="background-image: url('img/T-1.png')"
              >
              </div>
            </div>
            <div class="col-md-5">
              <!-- First image on the right side, above the article -->
              <div
                class="image-container image-right d-none d-sm-block"
                style="background-image: url('img/T-2.png')"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button } from '@/components';
export default {
  components: {
    [Button.name]: Button
  },
  name: 'books',
  bodyClass: 'landing-page'
};
</script>
<style></style>
