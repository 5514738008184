<template>
  <router-link :to="navUrl" class="creature-card mb-5">
    <div class="image" :class="creature.biome">
      <img :src="imgUrl" :title="creature.name" :alt="creature.name" />
      <div class="base"></div>
    </div>
    <h3 class="name">{{creature.name}}</h3>
    <span class="volume btn btn-round btn-primary btn-sm">Vol. {{creature.volume}}</span>
  </router-link>
</template>
<script>
export default {
  name: 'CreatureCard',
  props: ['creature'],
  computed: {
    navUrl: function() {
      return '/creatures/' + this.creature.url;
    },
    imgUrl: function() {
      return '/img/creatures/' + this.creature.imgsrc.toLowerCase();
    }
  }
};
</script>
<style>
.creature-card {
  border:1px solid #bbbbbb;
  border-radius:10px;
  overflow:hidden;
  display:block;
  position:relative;
  transition: 0.3s ease-in-out;
}

.creature-card:hover {
  border:1px solid #845f9c;
  text-decoration:none;
  box-shadow: 0px 0px 8px #BBBBBB;
}

.creature-card .image {
  width:100%;
  text-align:center;
  padding-top:10px;
}

.creature-card .image.burr .base { background-image: linear-gradient(#feffff, #c0e8fb ); }
.creature-card .image.above .base { background-image: linear-gradient(#a68cc2, #d0b8d9 ); }
.creature-card .image.dune .base { background-image: linear-gradient(#90b2d4, #ced4ea ); }
.creature-card .image.sprout .base { background-image: linear-gradient(#74cee2, #bfe5e6 ); }
.creature-card .image.grassby .base { background-image: linear-gradient(#6ecff6, #bde6fb ); }
.creature-card .image.ferno .base { background-image: linear-gradient(#fcc18b, #fff4c6 ); }
.creature-card .image.ocean .base { background-image: linear-gradient(#92d6e3, #d0ebe7 ); }

.creature-card .image .base {
  border-bottom:2px solid #bbbbbb;
  height:150px;
  position:absolute;
  top:0px;
  width:100%;
  z-index:1;
}


.creature-card .image img {
  width:80%;
  position:relative;
  bottom:0px;
  z-index:2;
}

.creature-card h3 {
  text-decoration:none;
  margin:0px;
  padding:10px;
}

.creature-card .volume {
  position:absolute;
  top:0px;
  right:10px;
  z-index:3;
}
</style>
