export let creatures = [
        {
            name: 'Akimbo',
            url: 'akimbo',
            description: 'Akimbos rule the Sprout Jungle. They are fantastic climbers and live up in the treetops.',
            funFact: 'Akimbos are terrified of heights. Luckily, the trees are short but sometimes you’ll hear a little yelp when they venture too high.',
            imgsrc: 'Akimbo.png',
            biome: 'sprout',
            rarity: 1,
            oddity: 3,
            jollity: 6,
            volume: '1',
            quantity: 6,
            openseaUrl: 'https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/99907212271085938437071820202829573110573260460089999685572971672245572206598'
        },
        {
            name: 'Bragoon',
            url: 'bragoon',
            description: 'Bragoons live in the volcanic wastelands of Ferno, where they guard 95% of planet Doodell’s gold. They are great accountants.',
            funFact: 'Science always asks Flying Eye Inc. for help with the year-end taxes.',
            imgsrc: 'Bragoon.png',
            biome: 'ferno',
            rarity: 1,
            oddity: 5,
            jollity: 2,
            volume: '1',
            quantity: 6,
            openseaUrl: 'https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/99907212271085938437071820202829573110573260460089999685572971673345083834374'
        },
        {
            name: 'Crank',
            url: 'crank',
            description: 'Cranks think they are sooo funny. They sneak up on you when you least expect it, but hide when you turn around.',
            funFact: 'There\'s a crank behind you right now... Seriously.',
            imgsrc: 'Crank.png',
            biome: 'grassby',
            rarity: 1,
            oddity: 10,
            jollity: 10,
            volume: '1',
            quantity: 6,
            openseaUrl: 'https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/99907212271085938437071820202829573110573260460089999685572971674444595462150'
        },
        {
            name: 'Duggy',
            url: 'duggy',
            description: 'Duggys use their incredible hearing to navigate underground tunnels. They can hear a Plibble fart from five moles away!',
            funFact: 'The glasses are for show. Duggys are totally blind, but don’t they look smart?',
            imgsrc: 'Duggy.png',
            biome: 'dune',
            rarity: 1,
            oddity: 2,
            jollity: 7,
            volume: '1',
            quantity: 6,
            openseaUrl: 'https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/99907212271085938437071820202829573110573260460089999685572971675544107089926'
        },
        {
            name: 'Eyeup',
            url: 'eyeup',
            description: 'Now then, now then. Eyeups have 360 degree vision. They literally have eyes in the back of their heads.',
            funFact: 'Their snazzy jackets are made from hairs they pluck from their own legs. In fact, Eyeups knit clothes for many creatures on Doodell.',
            imgsrc: 'Eyeup.png',
            biome: 'dune',
            rarity: 1,
            oddity: 7,
            jollity: 5,
            volume: '1',
            quantity: 6,
            openseaUrl: 'https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/99907212271085938437071820202829573110573260460089999685572971676643618717702'
        },
        {
            name: 'Fluffle',
            url: 'fluffle',
            description: 'Fluffles are tiny bundles of joy that live in the fur of Wilfs. If you shake a Wilf, thousands of Fluffles will fall out.',
            funFact: 'Since visiting Doodell, Science has developed really bad fluffdruff.',
            imgsrc: 'Fluffle.png',
            biome: 'burr',
            rarity: 2,
            oddity: 3,
            jollity: 8,
            volume: '1',
            quantity: 5,
            openseaUrl: 'https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/99907212271085938437071820202829573110573260460089999685572971677743130345477'
        },
        {
            name: 'Grumple',
            url: 'grumple',
            description: 'Grumples have an amazing sense of smell. Doodell is a really stinky place... maybe that\'s why Grumples are so grouchy?',
            funFact: 'Ever wondered what a sad laugh sounds like? Tickle a Grumple and you’ll find out.',
            imgsrc: 'Grumple.png',
            biome: 'sprout',
            rarity: 2,
            oddity: 7,
            jollity: 1,
            volume: '1',
            quantity: 5,
            openseaUrl: 'https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/99907212271085938437071820202829573110573260460089999685572971678842641973253'
        },
        {
            name: 'Hanz',
            url: 'hanz',
            description: 'Hanz have 4 hands and no feet, but they have 4 legs and no arms. Don\'t worry, we’re also confused.',
            funFact: 'Hanz are great gymnasts and fling themselves from tree to tree like flying balls of sprog.',
            imgsrc: 'Hanz.png',
            biome: 'sprout',
            rarity: 2,
            oddity: 6,
            jollity: 6,
            volume: '1',
            quantity: 5,
            openseaUrl: 'https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/99907212271085938437071820202829573110573260460089999685572971679942153601029'
        },
        {
            name: 'Ignus',
            url: 'ignus',
            description: 'Igni aren’t born, they just appear when larger Igni fall apart. For some reason, Science really likes to keep them as pets.',
            funFact: 'The largest Ignus ever discovered weighed 184 snots and has lived for countless rotations (because Igni can’t count).',
            imgsrc: 'Ignus.png',
            biome: 'ferno',
            rarity: 2,
            oddity: 1,
            jollity: 2,
            volume: '1',
            quantity: 5,
            openseaUrl: 'https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/99907212271085938437071820202829573110573260460089999685572971681041665228805'
        },
        {
            name: 'Jareef',
            url: 'jareef',
            description: 'A Jareef\'s head faces backwards. They never see where they are going, only where they have been.',
            funFact: 'A wise Jareef once said, "face the future like a Jareef faces the past".',
            imgsrc: 'Jareef.png',
            biome: 'dune',
            rarity: 2,
            oddity: 4,
            jollity: 7,
            volume: '1',
            quantity: 5,
            openseaUrl: 'https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/99907212271085938437071820202829573110573260460089999685572971682141176856581'
        },
        {
            name: 'Kiddo',
            url: 'kiddo',
            description: 'How on Doodell did that Kiddo get up there? Kiddos can climb anything, and glide through the air with their tiny wings.',
            funFact: 'Kiddos will eat absolutely anything. Grass, rocks, warm sprog, your lunch... anything!',
            imgsrc: 'Kiddo.png',
            biome: 'burr',
            rarity: 3,
            oddity: 2,
            jollity: 8,
            volume: '1',
            quantity: 4,
            openseaUrl: 'https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/99907212271085938437071820202829573110573260460089999685572971683240688484356'
        },
        {
            name: 'Lloyd',
            url: 'lloyd',
            description: 'Lloyds have multi-directional subnautic octo-vision. They make great opticians.',
            funFact: 'Science discovered that Lloyds wear wigs. Now Lloyds don’t believe in Science.',
            imgsrc: 'Lloyd.png',
            biome: 'ocean',
            rarity: 3,
            oddity: 8,
            jollity: 9,
            volume: '1',
            quantity: 4,
            openseaUrl: 'https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/99907212271085938437071820202829573110573260460089999685572971684340200112132'
        },
        {
            name: 'Mr SHPooPLE',
            url: 'mr-shpoople',
            description: 'Not much is known about Mr SHPooPLEs. They love to lick noses, and for that reason, Science doesn\'t like to get too close. ',
            funFact: 'Apparently if you lick a Mr SHPooPLE’s nose, you can see a glimpse of the future. That’s if they don’t lick your nose first!',
            imgsrc: 'MrSHPooPLE.png',
            biome: 'grassby',
            rarity: 3,
            oddity: 10,
            jollity: 4,
            volume: '1',
            quantity: 4,
            openseaUrl: 'https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/99907212271085938437071820202829573110573260460089999685572971685439711739908'
        },
        {
            name: 'Nad',
            url: 'nad',
            description: 'Nads live in the Specific Ocean, but they can also fly out of water. They are extremely fast and hard to catch.',
            funFact: 'Nads swim so fast, they can launch themselves into space! In fact, that\'s how Science found Doodell: flying space Nads...',
            imgsrc: 'Nad.png',
            biome: 'ocean',
            rarity: 3,
            oddity: 4,
            jollity: 6,
            volume: '1',
            quantity: 4,
            openseaUrl: 'https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/99907212271085938437071820202829573110573260460089999685572971686539223367684'
        },
        {
            name: 'Ocean Pony',
            url: 'ocean-pony',
            description: 'Once thought to be mythical creatures until Science saw one. Honestly, they did!',
            funFact: 'If you feed an Ocean Pony a Sea Biscuit, you have a friend for life.',
            imgsrc: 'OceanPony.png',
            biome: 'ocean',
            rarity: 3,
            oddity: 4,
            jollity: 7,
            volume: '1',
            quantity: 4,
            openseaUrl: 'https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/99907212271085938437071820202829573110573260460089999685572971687638734995460'
        },
        {
            name: 'Plibble',
            url: 'plibble',
            description: 'Plibbles have no bones, they are seriously rude, and they smell terrible. On the other hand, they give the best massages!',
            funFact: 'It\'s incredible how much you can fit inside a Plibble\'s belly button.',
            imgsrc: 'Plibble.png',
            biome: 'ferno',
            rarity: 4,
            oddity: 9,
            jollity: 1,
            volume: '1',
            quantity: 3,
            openseaUrl: 'https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/99907212271085938437071820202829573110573260460089999685572971688738246623235'
        },
        {
            name: 'Quinn',
            url: 'quinn',
            description: 'In a rush to get somewhere? Not this Quinn! Quinns have no agenda and move so slowly that Science fell asleep while studying them.',
            funFact: 'Quinns often get stuck to the ice because they linger too long! So much linger.',
            imgsrc: 'Quinn.png',
            biome: 'burr',
            rarity: 4,
            oddity: 6,
            jollity: 5,
            volume: '1',
            quantity: 3,
            openseaUrl: 'https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/99907212271085938437071820202829573110573260460089999685572971689837758251011'
        },
        {
            name: 'Roland',
            url: 'roland',
            description: 'Wherever there\'s music and a disco ball, there\'s a Roland. They shine their knees especially so they can dance faster. ',
            funFact: 'Roland\'s favourite genre of music is electro-jazz-punk. It isn\'t very good.',
            imgsrc: 'Roland.png',
            biome: 'ocean',
            rarity: 4,
            oddity: 7,
            jollity: 10,
            volume: '1',
            quantity: 3,
            openseaUrl: 'https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/99907212271085938437071820202829573110573260460089999685572971690937269878787'
        },
        {
            name: 'Sheldon',
            url: 'sheldon',
            description: 'Sheldi have eggcellent fashion sense but they tell really bad yolks.',
            funFact: 'What does a meditating Sheldon say?  "Ohhmmmmmmmlet".',
            imgsrc: 'Sheldon.png',
            biome: 'dune',
            rarity: 4,
            oddity: 8,
            jollity: 9,
            volume: '1',
            quantity: 3,
            openseaUrl: 'https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/99907212271085938437071820202829573110573260460089999685572971692036781506563'
        },
        {
            name: 'Toast Cat',
            url: 'toast-cat',
            description: 'This picture is wrong. Toast Cats have nothing to do with hot bread. They\'re just really good at giving speeches at weddings.',
            funFact: 'Toast Cats always land on their feet, and butter side down.',
            imgsrc: 'ToastCat.png',
            biome: 'grassby',
            rarity: 4,
            oddity: 5,
            jollity: 8,
            volume: '1',
            quantity: 3,
            openseaUrl: 'https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/99907212271085938437071820202829573110573260460089999685572971693136293134339'
        },
        {
            name: 'Ursi',
            url: 'ursi',
            description: 'Cute and scary at the same time? Never try to pet or cuddle an Ursi. Science learned that the hard way.',
            funFact: 'Ursi shrink in the wash and, therefore, can NEVER get wet.',
            imgsrc: 'Ursi.png',
            biome: 'sprout',
            rarity: 5,
            oddity: 6,
            jollity: 5,
            volume: '1',
            quantity: 2,
            openseaUrl: 'https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/99907212271085938437071820202829573110573260460089999685572971694235804762114'
        },
        {
            name: 'Vessa',
            url: 'vessa',
            description: 'Vessas love all things shiny. They are often found trying to steal gold from Bragoons.',
            funFact: 'When Science first arrived on Doodell, a sneaky Vessa stole Science\'s space hopper!',
            imgsrc: 'Vessa.png',
            biome: 'ferno',
            rarity: 5,
            oddity: 3,
            jollity: 3,
            volume: '1',
            quantity: 2,
            openseaUrl: 'https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/99907212271085938437071820202829573110573260460089999685572971695335316389890'
        },
        {
            name: 'Wilf',
            url: 'wilf',
            description: 'Wilfs live deep in the ice caves of Burr. Somehow they don\'t feel the cold and even wear shorts in winter! Crazy.',
            funFact: 'Wilfs have thousands of Fluffles huddled in their fur for warmth. It can get very noisy in there.',
            imgsrc: 'Wilf.png',
            biome: 'burr',
            rarity: 5,
            oddity: 2,
            jollity: 4,
            volume: '1',
            quantity: 2,
            openseaUrl: 'https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/99907212271085938437071820202829573110573260460089999685572971696434828017666'
        },
        {
            name: 'XT-01',
            url: 'xt-01',
            description: 'The XT-01 are not native to Doodell. They are thought to have come from a future Science experiment that went wrong... we\'re waiting to see.',
            funFact: 'The Guild of Eyeup Knitters made these lovely cardigans for the XT-01.',
            imgsrc: 'XT-01.png',
            biome: 'above',
            rarity: 5,
            oddity: 1,
            jollity: 9,
            volume: '1',
            quantity: 2,
            openseaUrl: 'https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/99907212271085938437071820202829573110573260460089999685572971697534339645442'
        },
        {
            name: 'Yoohoo',
            url: 'yoohoo',
            description: 'Yoohoos are always down to party. In fact, they celebrate their birthday every single day of the rotation.',
            funFact: 'Never buy a Yoohoo a birthday present. They will expect one every day from then on. Science had to take out a loan to cover buying presents for Yoohoos.',
            imgsrc: 'Yoohoo.png',
            biome: 'grassby',
            rarity: 5,
            oddity: 9,
            jollity: 10,
            volume: '1',
            quantity: 2,
            openseaUrl: 'https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/99907212271085938437071820202829573110573260460089999685572971652454362906626'
        },
        {
            name: 'Zopkios',
            url: 'zopkios',
            description: 'There is only one Zopkios. The only sightings have been on the 29th of Flobuary on a leap rotation, at midnight when the sky is clear and the Third Moon is full.',
            funFact: 'If you see a Zopkios, please call 1-800-SCIENCE immediately.',
            imgsrc: 'Zopkios.png',
            biome: 'above',
            rarity: 6,
            oddity: 5,
            jollity: 3,
            volume: '1',
            quantity: 1,
            openseaUrl: 'https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/99907212271085938437071820202829573110573260460089999685572971698633851273217'
        }
    ];
  